<template>
  <section id="admin-latest-news" class="panel">
    <ImageGallery
      v-for="(post, postIndex) in sortedPostsByDate"
      :key="post.post_date"
      :images="post.post_images"
      :index="index[postIndex]"
      :disableScroll="true"
      :glideParams="this.galleryGlideQueryString"
      @close="index[postIndex] = null" />
    <div class="inset-content">
      <div id="posts-wrapper" v-if="validToken">
        <div id="posts-grid">
          <h1>Admin - Lastest News Posts</h1>
          <router-link to="/admin/news-item/add"
            ><button class="button">Add New Post</button></router-link
          >
          <div id="posts" class="scrollable-content">
            <div
              class="post-grid span-row"
              v-for="(post, postIndex) in sortedPostsByDate"
              :key="post.post_date">
              <div class="post-title">
                <h2>{{ post.title }}</h2>
              </div>
              <div class="post-date">
                <h2>{{ prettyDate(post.post_date) }}</h2>
              </div>
              <div class="post-body span-row" v-html="post.body"></div>
              <div id="post-image-wrapper" class="span-row">
                <div
                  v-for="(thumb, thumbIndex) in post.post_images"
                  :key="thumbIndex"
                  class="post-image"
                  @click="index[postIndex] = thumbIndex">
                  <img class="post-image-thumb" :src="thumb" />
                </div>
              </div>
              <div class="post-controls span-row">
                <button class="button" @click="editPostItem(post.id)">
                  Edit
                </button>
                <button class="button" @click="deletePostItem(post.id)">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!validToken" class="unauthorised">Unauthorised</div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { useToast } from 'vue-toastification'
import { addPaddingToScrollableContent } from '@/assets/scripts/globals'
import { gsap } from 'gsap/all'
import ImageGallery from '@/components/Latest News/ImageGallery'

export default {
  name: 'AdminItem',
  components: {
    ImageGallery,
  },
  setup() {
    // Setup Toast notifcations
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      posts: [], //Create an array in advance to store the requested data
      images: [
        {
          url: '',
        },
      ],
      index: [],
      glideQueryString: 'w=125&h=125&fit=crop',
      galleryGlideQueryString: 'w=2160&h=1440',
    }
  },
  created() {
    this.retrievePosts()
  },
  destroyed() {
    window.removeEventListener('resize', addPaddingToScrollableContent)
  },
  mounted() {
    if (!this.validToken) {
      this.$router.push({ path: '/admin/login' })
    }
    window.addEventListener('resize', addPaddingToScrollableContent)
  },
  updated() {
    this.$nextTick(function () {
      addPaddingToScrollableContent()
    })
  },
  computed: {
    validToken() {
      if (this.$store.state.token != null) {
        return Boolean(~this.$store.state.token.length)
      } else {
        return false
      }
    },
    sortedPostsByDate() {
      return this.posts
        .map((post) => ({
          post_date: post.post_date,
          body: post.body,
          id: post.id,
          post_images: post.post_images,
          title: post.title,
          updated_at: post.updated_at,
        }))
        .sort(function (a, b) {
          return b.post_date > a.post_date
            ? 1
            : b.post_date < a.post_date
            ? -1
            : 0
        })
    },
  },
  methods: {
    retrievePosts() {
      let databaseFile = process.env.VUE_APP_ROOT_API + 'latest-news/read.php'

      axios
        .get(databaseFile)
        .then((response) => {
          if (!response.data.message) {
            this.posts = response.data.body
            // Format the post results correctly for display by the ImageGallery component.
            this.posts.forEach((post, postIndex) => {
              if (JSON.parse(post.post_images) != '') {
                // Retrieve images for post from returned json
                post.post_images = JSON.parse(post.post_images)

                // get signed token for each image
                post.post_images.forEach((image, imageIndex) => {
                  let retrieveImageTokenURL =
                    process.env.VUE_APP_ROOT_API + 'admin/get_image_token.php'
                  axios
                    .post(retrieveImageTokenURL, {
                      image: image,
                      queryParams: this.glideQueryString,
                    })
                    .then((response) => {
                      this.posts[postIndex].post_images[imageIndex] =
                        response.data
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                })
              } else {
                post.post_images = []
              }
              this.index[postIndex] = null
            })
            this.$nextTick(() => {
              gsap.fromTo(
                '#posts-grid *',
                { autoAlpha: 0, y: -10 },
                {
                  autoAlpha: 1,
                  y: 0,
                  z: 20,
                  stagger: 0.01,
                  delay: 0.9,
                  onComplete: () => {
                    document.getElementById('posts-grid').style.overflowY =
                      'auto'
                  },
                }
              )
            })
          } else {
            this.toast.error('No posts found!')
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.message == 'Network Error') {
              this.toast.error(
                `Could not connect to database to retrieve latest posts using URL ${error.config.url}`
              )
            } else if (error.response.data.message === 'No record found.') {
              this.$router.push('/admin/news-item/add').catch((failure) => {
                console.log(failure)
              })
            }
          } else {
            this.toast.error(`Couldn't connect to database: ${error}`)
          }
        })
    },
    editPostItem(id) {
      this.$router.push({ name: 'EditPostItem', params: { id: id } })
    },
    deletePostItem(id) {
      let self = this
      let databaseFile = process.env.VUE_APP_ROOT_API + 'latest-news/delete.php'
      let jsonData = new Object()
      jsonData.post_id = id
      axios
        .post(databaseFile, jsonData, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`,
          },
        })
        .then(async (response) => {
          if (response.data === 'Post deleted.') {
            self.toast.success(response.data)
            this.retrievePosts()
          } else {
            this.toast(this.strip(response.data).trim(), {
              type: 'error',
              timeout: 60000,
            })
          }
        })
        .catch((error) => {
          this.toast(
            `Error when submitting the post: The error was "${error}"`,
            { type: error, timeout: 30000 }
          )
        })
      this.$router.push({ name: 'AdminItem' })
    },
    strip(html) {
      let string = html.replace(/<br ?\/?>/gi, '\n')
      let doc = new DOMParser().parseFromString(string, 'text/html')
      return doc.body.textContent || ''
    },
    prettyDate(date) {
      var correctedDate = new Date(`${date.replace(' ', 'T')}`)

      var returnedDate = new Date(correctedDate).toLocaleDateString('en-UK', {
        year: 'numeric',
        month: 'long',
      })
      return returnedDate
    },
  },
}
</script>

<style lang="scss" scoped>
#inset-content {
  width: 80vw;
  display: inline-block;
}

#posts-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  overflow: hidden;
}

#posts-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px;
  width: 85vw;
  overflow-y: hidden !important;
  column-gap: 0px;
  height: 76vh;
  padding-top: 5vmin;
}

#posts {
  align-self: start;
  overflow-y: scroll;
  height: 100%;
  margin-top: 1em;
}

.post-grid {
  display: grid;
  grid-template-columns: minmax(70%, 1fr) 3fr;
  row-gap: 0px;
  column-gap: 0px;
  margin-right: 2em;
}

.span-row {
  grid-column: span 2 / auto;
  align-self: start;
}

.post-title {
  justify-self: start;
}

.post-date {
  justify-self: end;
}

.post-body {
  margin-left: 1em;
  color: rgb(172, 172, 172);
}

.post-controls {
  justify-self: end;
  margin-top: 1.8em;
  margin-bottom: 0.7em;
}

.control-label {
  text-transform: uppercase;
}

#posts-grid H1 {
  margin-top: 0;
  margin-bottom: 0.6em;
}

#post-image-wrapper {
  margin-left: 1.3em;
  margin-top: 0.5em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, auto));
  -moz-column-gap: 0.7em;
  column-gap: 0.7em;
  row-gap: 0.7em;
  grid-auto-rows: 75px;
  grid-auto-columns: 75px;
  justify-content: start;
}
.post-image {
  width: 75px;
  height: 75px;
  display: inline-block;
  position: relative;

  &:hover {
    outline: 1px solid $makeitsopurplelight;
  }
}

.post-image img {
  object-fit: cover;
  width: 75px;
  height: 75px;
}

.post-image-thumb {
  display: block;
  width: 100%;
  height: auto;

  &:hover {
    cursor: pointer;
  }
}
</style>